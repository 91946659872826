// 字体大小 fz-10 fz-24
.loopFont(@i) when (@i<=24) {
    .fz-@{i} {
        font-size: ~'@{i}px';
    }
    .loopFont(@i+1);
}
.loopFont(10);
// 粗体
.fw-600 {
    font-weight: 600;
}
// 布局
.pa {
    position: absolute;
}
.flex {
    display: flex;
}
.flex1 {
    flex: 1;
}
.ac {
    align-items: center;
}
.c {
    align-items: center;
    justify-content: center;
}
.as {
    align-items: flex-start;
}
.ae {
    align-items: flex-end;
}
.jc {
    justify-content: center;
}
.jsb {
    justify-content: space-between;
}
.js {
    justify-content: flex-start;
}
.je {
    justify-content: flex-end;
}
.f-d-c {
    flex-direction: column;
}
.f-w {
    flex-wrap: wrap;
}

.tr {
    text-align: right;
}
.tc {
    text-align: center;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.radius-8 {
    border-radius: 8;
}
// 其他
.cursor-p {
    cursor: pointer;
}
.cursor-d {
    cursor: default;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
