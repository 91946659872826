.fz-10 {
  font-size: 10px;
}

.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-15 {
  font-size: 15px;
}

.fz-16 {
  font-size: 16px;
}

.fz-17 {
  font-size: 17px;
}

.fz-18 {
  font-size: 18px;
}

.fz-19 {
  font-size: 19px;
}

.fz-20 {
  font-size: 20px;
}

.fz-21 {
  font-size: 21px;
}

.fz-22 {
  font-size: 22px;
}

.fz-23 {
  font-size: 23px;
}

.fz-24 {
  font-size: 24px;
}

.fw-600 {
  font-weight: 600;
}

.pa {
  position: absolute;
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.ac {
  align-items: center;
}

.c {
  justify-content: center;
  align-items: center;
}

.as {
  align-items: flex-start;
}

.ae {
  align-items: flex-end;
}

.jc {
  justify-content: center;
}

.jsb {
  justify-content: space-between;
}

.js {
  justify-content: flex-start;
}

.je {
  justify-content: flex-end;
}

.f-d-c {
  flex-direction: column;
}

.f-w {
  flex-wrap: wrap;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.radius-8 {
  border-radius: 8px;
}

.cursor-p {
  cursor: pointer;
}

.cursor-d {
  cursor: default;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*# sourceMappingURL=merge.5e7df3fa.css.map */
